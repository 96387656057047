import cleanDeep from 'clean-deep';

import AuthenticatedResource from './authenticatedResource';

export default class ProjectManagerResource extends AuthenticatedResource {
  id = 'no-need';

  pk() {
    return this.id;
  }

  static createShape() {
    const superShape = super.createShape();

    return {
      ...superShape,
      fetch: async (params, body) => {
        const response = await superShape.fetch(params, cleanDeep(body));

        return response;
      },
      schema: null
    };
  }

  static urlRoot = `${CONFIG.apiBaseUrl}/project-manager`;
}
