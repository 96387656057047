// import { Avatar, Button, Carousel } from 'antd';
import { useRequest } from 'ahooks';
import { Button, Form, Input } from 'antd';
import classNames from 'classnames';

import { RichTextEditorFormItem } from '@/components';
import pluralize, { WORD } from '@/helpers/pluralize';
import { OrderResource, ProjectManagerResource } from '@/resources';

require('./styles.less');

const LandingPageContainer = (props) => {
  const { type, type2 } = props;

  const ordersCount = useRequest(() => OrderResource.listShape().fetch({ perPage: 0 }), {
    initialData: {}
  });

  // const sliderProps = {
  //   className: 'slider',
  //   centerMode: true,
  //   infinite: true,
  //   variableWidth: true,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   focusOnSelect: true
  // };

  if (type === 'projectManager') {
    return <ProjectManagerLanding />;
  }

  return (
    <div className={classNames('landing-page', { 'landing-page_type-2': type2 })}>
      <div className="landing-page__hero">
        <div className="landing-page__wrapper">
          <h1 className="landing-page__hero-slogan">Найдется производитель для любого заказа</h1>
          <p className="landing-page__hero-text">
            {pluralize(ordersCount.data?.total ?? 0, WORD.CLIENT.NOMINATIVE)} доверили заказы нашему сервису
          </p>
          <Button type="primary" danger href="/order">
            Найти исполнителя
          </Button>
        </div>
      </div>
      <div className="landing-page__block" id="technologies">
        <h2 className="landing-page__wide-title">Первый в России сервис, охватывающий все производственные сферы</h2>
        <div className="landing-page__wrapper landing-page__wrapper_sm">
          <div className="landing-page__techs">
            <a className="landing-page__tech" href="/orders/derevo">
              Деревообработка
            </a>
            <a className="landing-page__tech" href="/orders/metall">
              Металлообработка
            </a>
            <a className="landing-page__tech" href="/orders/plastic">
              Пластик
            </a>
            <a className="landing-page__tech" href="/orders/sborka">
              Контрактная сборка
            </a>
            <a className="landing-page__tech" href="/orders/electronika">
              Электроника
            </a>
            <a className="landing-page__tech" href="/orders/dizain">
              Дизайн
            </a>
            <a className="landing-page__tech" href="/orders/kompleksnoe-proizvodstvo">
              Комплексное производство
            </a>
            <a className="landing-page__tech" href="/orders/plastic">
              Композиты и полимеры
            </a>
            <a className="landing-page__tech" href="/orders/mebel">
              Мебельное производство
            </a>
          </div>
        </div>
      </div>
      <div className="landing-page__block landing-page__block_gears-background" id="producers">
        <div className="landing-page__wrapper landing-page__wrapper_sm">
          <h2>
            Как найти <span className="accent accent_fountain">исполнителя</span>?
          </h2>
          <div className="landing-page__executors">
            <div className="landing-page__executor">
              <p className="landing-page__executor-title">Зарегистрируйтесь на сайте</p>
              <p className="landing-page__executor-description">Зарегистрируйтесь или войдите в существующий аккаунт</p>
            </div>
            <div className="landing-page__executor">
              <p className="landing-page__executor-title">Разместите заказ</p>
              <p className="landing-page__executor-description">
                Детально опишите задачу, прикрепите чертежи и наброски, укажите сроки и бюджет
              </p>
            </div>
            <div className="landing-page__executor">
              <p className="landing-page__executor-title">Выберите исполнителя</p>
              <p className="landing-page__executor-description">
                Исполнители сами откликнутся на ваш заказ – выберите подходящих по рейтингу, отзывам и цене
              </p>
            </div>
            <div className="landing-page__executor">
              <p className="landing-page__executor-title">Заключите сделку</p>
              <p className="landing-page__executor-description">
                Свяжитесь с выбранными исполнителями и договоритесь о деталях
              </p>
            </div>
          </div>
          <div className="landing-page__action">
            <Button type="primary" ghost href="/order">
              Создать заказ
            </Button>
          </div>
        </div>
      </div>
      <div className="landing-page__block landing-page__block_gray" id="customers">
        <div className="landing-page__wrapper landing-page__wrapper_md">
          <h2>
            Как найти <span className="accent accent_highlight">заказчика</span>?
          </h2>
          <div className="landing-page__customers">
            <div className="landing-page__customer">
              <p className="landing-page__customer-title">Зарегистрируйтесь на сайте</p>
              <p className="landing-page__customer-description">
                Зарегистрируйтесь на сайте как юридическое лицо или ИП, пройдите проверку
              </p>
            </div>
            <div className="landing-page__customer">
              <p className="landing-page__customer-title">Откликнитесь на заказы</p>
              <p className="landing-page__customer-description">
                Задайте уточняющие вопросы к интересующим вас заказам
                <br />
                Оставьте отклик на заказ
              </p>
            </div>
            <div className="landing-page__customer">
              <p className="landing-page__customer-title">Заключите сделку</p>
              <p className="landing-page__customer-description">
                Когда заказчик выберет вас, договоритесь о деталях и приступайте к исполнению
              </p>
            </div>
          </div>
          <div className="landing-page__action">
            <Button type="primary" ghost danger href="/orders">
              Заказы
            </Button>
          </div>
        </div>
      </div>
      <div className="landing-page__block" id="features">
        <div className="landing-page__wrapper landing-page__wrapper_lg">
          <h2>Почему выбирают нас?</h2>
          <div className="landing-page__whys">
            <div className="landing-page__why">
              <p className="landing-page__why-title">Проверенные исполнители</p>
              <p className="landing-page__why-description">
                Отклики на заказы доступны только прошедшим проверку исполнителям
              </p>
            </div>
            <div className="landing-page__why">
              <p className="landing-page__why-title">Уникальный сервис</p>
              <p className="landing-page__why-description">
                Откликнитесь на часть заказа, если не готовы выполнить его полностью. Заказчик выберет нескольких
                исполнителей для своего заказа
              </p>
            </div>
            <div className="landing-page__why">
              <p className="landing-page__why-title">Взаимные отзывы</p>
              <p className="landing-page__why-description">
                Рейтинги и отзывы исполнителей и заказчиков помогут выбрать партнера, с которым будет комфортно работать
              </p>
            </div>
            <div className="landing-page__why">
              <p className="landing-page__why-title">Экономия времени</p>
              <p className="landing-page__why-description">
                Ответы на вопросы к заказу видны всем пользователям, чтобы не тратить время на уточнение нюансов
              </p>
            </div>
            <div className="landing-page__why">
              <p className="landing-page__why-title">
                Услуга{' '}
                <Button href="/project-manager" type="link">
                  менеджера проекта
                </Button>
              </p>
              <p className="landing-page__why-description">
                Вы можете нанять личного менеджера, который будет вести проект от момента размещения заказа и до
                получения конечного результата
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="landing-page__block landing-page__block_testimonials-background">
        <div className="landing-page__wrapper landing-page__wrapper_100">
          <h2>Что говорят наши клиенты</h2>
          <Carousel {...sliderProps} draggable arrows>
            <div className="slider__slide">
              <div className="slider__slide-header">
                <Avatar size={120} src="https://i1.sndcdn.com/avatars-000460031376-3vwg8i-t120x120.jpg" />
                <div className="slider__slide-name">Кот Котович</div>
                <div className="slider__slide-occupation">Сотрудник министерства не ваших собачьих дел</div>
              </div>
              <div className="slider__slide-testimonial">Вжух, и всё готово. Сам бы я не справился, у меня лапки.</div>
            </div>
            <div className="slider__slide">
              <div className="slider__slide-header">
                <Avatar
                  size={120}
                  src="https://pbs.twimg.com/profile_images/3169474099/9fbec78c3d655cd45b5db2a2c0a98a8c_400x400.png"
                />
                <div className="slider__slide-name">Некто из Фрязино</div>
                <div className="slider__slide-occupation">Специалист ОТК</div>
              </div>
              <div className="slider__slide-testimonial">
                Был рядом со всеми выполнеными на сервисе заказами. Всё было чётко.
              </div>
            </div>
            <div className="slider__slide">
              <div className="slider__slide-header">
                <Avatar size={120} src="https://www.meme-arsenal.com/memes/b77099975dd9bd672fe8ab1cd1b2b877.jpg" />
                <div className="slider__slide-name">Вячеслав Буйнов</div>
                <div className="slider__slide-occupation">Профессиональный переговорщик</div>
              </div>
              <div className="slider__slide-testimonial">
                Запилили дверь по высшему разряду без долгих препирательств. Исполнитель тоже оказался интересной
                личностью.
              </div>
            </div>
          </Carousel>
        </div>
      </div> */}
    </div>
  );
};

const ProjectManagerLanding = () => {
  const [form] = Form.useForm();
  const orderProjectManager = useRequest((body) => ProjectManagerResource.createShape().fetch({}, body), {
    initialData: {},
    manual: true
  });

  const onFinish = (formValues) => {
    orderProjectManager.run(formValues);
  };

  const scrollToForm = () =>
    document.getElementById('form').scrollIntoView({
      behavior: 'smooth'
    });

  const showForm = !orderProjectManager.data.status || orderProjectManager.data.error;

  return (
    <div className={classNames('landing-page', 'landing-page_type-project-manager')}>
      <div className="landing-page__hero">
        <div className="landing-page__wrapper">
          <h1 className="landing-page__hero-slogan">Предоставляем менеджеров проекта</h1>
          <p className="landing-page__hero-text">
            Менеджер поможет быстро реализовать ваш проект – найдет исполнителей и проконтролирует изготовление изделия
            до получения конечного результата
          </p>
          <Button type="primary" danger onClick={scrollToForm}>
            Нанять менеджера
          </Button>
        </div>
      </div>
      <div className="landing-page__block landing-page__block_gears-background">
        <div className="landing-page__wrapper landing-page__wrapper_md">
          <div className="landing-page__balloons">
            <div className="landing-page__balloon landing-page__balloon_question">
              <p>Зачем мне менеджер проекта?</p>
            </div>
            <div className="landing-page__balloon landing-page__balloon_answer">
              <p>
                Мы знаем, как трудоемко управление проектом. Процессы поиска подрядчика, организации логистики и решения
                текущих проблем часто затягиваются и отнимают много времени и сил.
              </p>
              <p>
                А если в проекте нужна новая, не профильная технология, то чтобы не возникло проблем при производстве, в
                нее придется погрузиться, изучить ограничения и тонкости использования.
              </p>
              <p>
                Поэтому мы предлагаем воспользоваться <b>услугой менеджера проекта</b> от нашей компании.
              </p>
            </div>
            <div className="landing-page__balloon landing-page__balloon_question">
              <p>Какие задачи решает менеджер проекта?</p>
            </div>
            <div className="landing-page__balloon landing-page__balloon_answer">
              <p>
                Мы подберем специалиста, который уже имел дело с применяемой технологией. Он поможет с поиском
                подходящих подрядчиков, организует их взаимодействие, логистику и приемку работ.
              </p>
              <p>
                Если продукт невозможно произвести по этой технологии, предложит альтернативу и поможет скорректировать
                чертежи и другую документацию по необходимости.
              </p>
              <p>
                <Button onClick={scrollToForm} type="link">
                  Наймите менеджера проекта
                </Button>{' '}
                и сэкономьте силы и время.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="landing-page__block landing-page__block_testimonials-background">
        <div className="landing-page__wrapper landing-page__wrapper_sm">
          <h2>Преимущества персонального менеджера</h2>
          <div className="landing-page__whys-personal-manager">
            <div className="landing-page__why-personal-manager">
              <p className="landing-page__why-title">Экономия вашего времени и сил</p>
              <p className="landing-page__why-description">
                Менеджер сокращает количество необходимых коммуникаций с подрядчиком и убирает необходимость вникать во
                все производственные процессы
              </p>
            </div>
            <div className="landing-page__why-personal-manager">
              <p className="landing-page__why-title">Контроль качества</p>
              <p className="landing-page__why-description">
                Менеджер осуществляет контроль качества вашего производственного заказа на каждом этапе его создания
              </p>
            </div>
            <div className="landing-page__why-personal-manager">
              <p className="landing-page__why-title">Логистика</p>
              <p className="landing-page__why-description">
                Вам не придется думать, как организовать логистику – менеджер берет на себя все логистические вопросы
              </p>
            </div>
            <div className="landing-page__why-personal-manager">
              <p className="landing-page__why-title">Изготовление заказа под ключ</p>
              <p className="landing-page__why-description">
                Менеджер может произвести ваш продукт полностью
                <br />
                «с нуля», так и реализовать любой из этапов производственного цикла
              </p>
            </div>
          </div>
          <div className="landing-page__action">
            <Button type="primary" ghost onClick={scrollToForm}>
              Нанять менеджера
            </Button>
          </div>
        </div>
      </div>
      <div className="landing-page__block landing-page__block_gears-background">
        <div className="landing-page__wrapper landing-page__wrapper_sm">
          <h2>Как это работает</h2>
          <div className="landing-page__personal-manager-tips">
            <div className="landing-page__personal-manager-tip">
              <p className="landing-page__personal-manager-tip-title">Оставьте заявку на сайте</p>
              <p className="landing-page__personal-manager-tip-description">
                Опишите задачу и прикрепите чертежи или наброски – наши сотрудники свяжутся с вами для уточнения деталей
                и расчета стоимости услуги
              </p>
            </div>
            <div className="landing-page__personal-manager-tip">
              <p className="landing-page__personal-manager-tip-title">Заключите договор</p>
              <p className="landing-page__personal-manager-tip-description">
                Мы предоставим вам личного менеджера или фирму, которая возьмет на себя вопросы контроля и
                взаиморасчетов с подрядчиками
              </p>
            </div>
            <div className="landing-page__personal-manager-tip">
              <p className="landing-page__personal-manager-tip-title">Менеджер начнет работу</p>
              <p className="landing-page__personal-manager-tip-description">
                Менеджер подберет для вас исполнителей, организует логистику и отследит проект на всех этапах до
                получения конечного результата
              </p>
            </div>
            <div className="landing-page__personal-manager-tip">
              <p className="landing-page__personal-manager-tip-title">Получите заказ</p>
              <p className="landing-page__personal-manager-tip-description">
                Вы получите готовое изделие, соответствующее вашим требованиям, и сэкономите свое время на контроль его
                изготовления
              </p>
            </div>
          </div>
          <div className="landing-page__action">
            <Button type="primary" ghost onClick={scrollToForm}>
              Оставить заявку
            </Button>
          </div>
        </div>
      </div>
      <div className="landing-page__block" id="form">
        <div className="landing-page__wrapper landing-page__wrapper_sm">
          <h2>Заявка на менеджера проекта</h2>
          <div className="landing-page__project-manager-form">
            {!showForm && <p>Благодарим за заявку. Мы свяжемся с вами в ближайшее время.</p>}
            {showForm && (
              <Form className="form" layout="vertical" form={form} onFinish={onFinish} scrollToFirstError>
                <Form.Item
                  label="Задача для менеджера"
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: 'Заполните описание задачи'
                    }
                  ]}
                >
                  <RichTextEditorFormItem placeholder="Организовать процесс производства партии из 150 роботизированных рук для технологической линии" />
                </Form.Item>
                <Form.Item
                  label="Ваше имя"
                  name="name"
                  rules={[
                    {
                      required: true,
                      min: 2,
                      message: 'Заполните ваше имя'
                    }
                  ]}
                >
                  <Input placeholder="Иван" />
                </Form.Item>
                <Form.Item
                  label="Телефон и/или e-mail для связи"
                  name="contactData"
                  rules={[
                    {
                      required: true,
                      min: 7,
                      message: 'Заполните данные для связи'
                    }
                  ]}
                >
                  <Input placeholder="+7 (123) 456-78-90 / ivan@ivanov.net" />
                </Form.Item>
                <div className="landing-page__action">
                  <Button loading={orderProjectManager.loading} htmlType="submit" type="primary" ghost>
                    Отправить
                  </Button>
                </div>
              </Form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPageContainer;
