// Check yourself here: http://www.morpher.ru/SummaPro.aspx
import { pluralize } from 'numeralize-ru';

export const WORD = {
  ANSWER: {
    NOMINATIVE: ['ответ', 'ответа', 'ответов'],
    GENITIVE: ['ответа', 'ответов', 'ответов'],
    DATIVE: ['ответу', 'ответам', 'ответам'],
    ACCUSATIVE: ['ответ', 'ответа', 'ответов'],
    INSTRUMENTAL: ['ответом', 'ответами', 'ответами'],
    PREPOSITIONAL: ['ответе', 'ответах', 'ответах']
  },
  CLIENT: {
    NOMINATIVE: ['клиент', 'клиента', 'клиентов'],
    GENITIVE: ['клиента', 'клиентов', 'клиентов'],
    DATIVE: ['клиенту', 'клиентам', 'клиентам'],
    ACCUSATIVE: ['клиента', 'клиентов', 'клиентов'],
    INSTRUMENTAL: ['клиентом', 'клиентами', 'клиентами'],
    PREPOSITIONAL: ['клиенте', 'клиентах', 'клиентах']
  },
  RESPONSE: {
    NOMINATIVE: ['отклик', 'отклика', 'откликов'],
    GENITIVE: ['отклика', 'откликов', 'откликов'],
    DATIVE: ['отклику', 'откликам', 'откликам'],
    ACCUSATIVE: ['отклик', 'отклика', 'откликов'],
    INSTRUMENTAL: ['откликом', 'откликами', 'откликами'],
    PREPOSITIONAL: ['отклике', 'откликах', 'откликах']
  },
  VIEW: {
    NOMINATIVE: ['просмотр', 'просмотра', 'просмотров'],
    GENITIVE: ['просмотра', 'просмотров', 'просмотров'],
    DATIVE: ['просмотру', 'просмотрам', 'просмотрам'],
    ACCUSATIVE: ['просмотр', 'просмотра', 'просмотров'],
    INSTRUMENTAL: ['просмотром', 'просмотрами', 'просмотрами'],
    PREPOSITIONAL: ['просмотре', 'просмотрах', 'просмотрах']
  }
};

const getPluralizedWord = (count, words) => {
  return `${count} ${pluralize(count, ...words)}`;
};

export default getPluralizedWord;
